import styled from '@emotion/styled'
import {rhythm} from '../../lib/typography'

export default styled.h3`
  text-align: left;
  margin-top: ${rhythm(0.5)};
  margin-bottom: ${rhythm(0.5)};
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 900;
`
