import React from 'react'
import {css} from '@emotion/core'
import theme from '../../config/theme'
import {bpMaxSM} from '../lib/breakpoints'
import {Twitter, GitHub, RSS} from './social'
import Container from './container'
import {REDDIT} from 'components/social'

const Footer = ({maxWidth}) => (
  <footer
    css={css`
      background: ${theme.colors.coreWhite};
      color: white;
      margin-top: 0;
    `}
  >
    <Container
      maxWidth={maxWidth}
      css={css`
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        ${bpMaxSM} {
          padding-top: 0;
          flex-direction: column;
        }
      `}
    >
      {/*{subscribeForm ? (*/}
      {/*  <div css={{marginTop: -40}}>*/}
      {/*    {subscribeForm}*/}
      {/*    <br />*/}
      {/*    <br />*/}
      {/*  </div>*/}
      {/*) : null}*/}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          div,
          img {
            margin: 20px 0;
            ${bpMaxSM} {
              margin: 20px 0;
            }
          }
          ${bpMaxSM} {
            align-items: center;
          }
        `}
      >
        <div>
          <Twitter />
          <GitHub />
          <RSS />
          <REDDIT />
        </div>
      </div>
    </Container>
  </footer>
)

export default Footer
