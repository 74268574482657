module.exports = {
  siteTitle: 'James',
  siteTitleAlt: 'The personal website of James', // SEO
  siteTitleShort: 'james', // short_name - manifest
  siteUrl: process.env.ROOT_URL || 'https://jamechege.com', // Domain without trailing slash
  lang: 'en',
  pathPrefix: '/',
  siteLogo: 'images/logo.png', // for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'This is James website.',
  minibio: `
    <strong>James </strong> is a JavaScript software engineer.
  `,
  author: 'James', // Author for schemaORGJSONLD
  organization: 'James Tech LLC',

  userTwitter: '@chegehimself',
  ogSiteName: 'James',
  ogLanguage: 'en_US',

  // Manifest and Progress color
  themeColor: '#4147DC',
  backgroundColor: '#231C42',

  // Social component
  twitter: 'https://twitter.com/chegehimself/',
  twitterHandle: '@chegehimself',
  github: 'https://github.com/chegehimself/',
  linkedin: 'https://www.linkedin.com/in/james-chege-20091b125/',
  youtube: 'https://www.youtube.com/channel/UC2rEkfTKveI19Z98Nb-_hnA',
  rss: 'https://jameschege.com//blog/rss.xml',
  reddit: 'https://www.reddit.com/user/cheichege',
}
